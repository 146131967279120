@import "abstracts/_abstracts";
.AppHeader {
	$block: &;
	position: relative;
	z-index: var(--layerHeaderZIndex);
	padding-top: var(--spaceRg);
	padding-bottom: var(--spaceRg);

	&-nav {
		display: flex;
		justify-content: flex-end;
	}

	&-content {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 2rem;

		& > *:not(:first-child) {
			padding-left: 2rem;
			border-left: 1px solid var(--colorOutlines);
		}
	}

	&-col {
		&--logo {

		}

		&--content {
			display: flex;
			flex-flow: column;
			gap: 2.25rem;

			@include media("<=xs") {
				flex-flow: row;
				align-items: center;
			}
		}
	}

	&-frame {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@include media("<=xs") {
			align-items: center;
		}
	}

	&-claim {
		position: relative;
		font-size: 1rem;
		font-weight: 200;
		text-transform: uppercase;
		padding-left: .5rem;
		margin: 0;
		transition: all .25s ease-in-out;
		line-height: 1.5rem;

		@include media("<=xs") {
			line-height: 1rem;
			font-size: .75rem;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 1px;
			height: 100%;
			background-color: var(--colorOutlines);
		}
	}

	&-logo {
		max-width: 15rem;
		text-align: center;
		margin-right: auto;
		display: flex;
		flex-flow: row;
		align-items: center;
		gap: 1rem;
		color: var(--colorText);

		@include on-event {
			text-decoration: none;
		}

		img, svg {
			max-width: 100%;
			height: auto;
			transition: all .25s ease-in-out;

			@include media("<=xs") {
				height: 3rem;
				width: auto;
			}
		}
	}

	&-contact {
		font-weight: 300;
		font-size: var(--textSm);
		margin-bottom: .4em;

		@include media('<=md') {
			display: none;
		}

		a {
			color: inherit;
			color: var(--textSubtle);

			svg {
				color: var(--colorBrand);
			}
		}
	}

	&-boxes {
		display: flex;
		align-items: center;
		position: relative;
		gap: 0.5rem;
		align-self: stretch;

		&--phone {
			@include media("<=xs") {
				display: none;
			}
		}

		&--email {
			@include media("<=sm") {
				display: none;
			}
		}

		&--search {
			border-left: none !important;

			@include media(">md") {
				display: none;
			}
		}

		&--col {
			flex-flow: column;
		}
	}

	&-button {
		@include media('<=sm') {
			display: none;
		}
	}

	&-hmb {
		display: flex;
		gap: 0.5rem;
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: 700;
		cursor: pointer;

		svg {
			width: 1rem;
			height: auto;
		}

		@include media('>sm') {
			display: none;
		}
	}

	&-sticky{
		position: sticky;
		top: 0;
		background: white;
		width: 100%;
		border-bottom: 1px solid var(--colorOutlines);
	}
}
